<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col lg="6" md="6" sm="12" class="mb-1 mb-md-0">
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <label>Show</label>
                <v-select
                  v-model="per_page"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="per_pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-button
                  class="table-header-btn"
                  variant="primary"
                  :to="{ name: 'register-content-upload', params: { type: 'Audio-Visual/Dramatic Works'} }"
                >
                  Upload new Content
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col lg="6" md="6" sm="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAudioVisualsList"
        class="position-relative font-weight-bolder"
        :items="fetchAudioVisuals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`${resolveContentVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Uploaded On -->
        <template #cell(uploaded_on)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moment(data.item.created_at).format("dddd, MMMM Do YYYY")
            }}</span>
          </div>
        </template>

        <!-- Roles -->
        <template #cell(roles)="data">
          <div class="text-nowrap">
            <b-badge
              v-for="item in data.item.content"
              :key="item.id"
              pill
              variant="light-success"
              class="text-capitalize"
            >
              {{ getContentRoles(item) }}
            </b-badge>
          </div>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap" v-if="data.item.status !== 'Approved'">
            <b-button size="sm" variant="secondary" :to="{ name: 'content-edit', params: { id: data.item.id } }">Edit</b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalRecords"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BCardText,
  BCardBody,
  BTableLite,
  VBToggle,
  BTooltip,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useAudioVisualsList from "./useAudioVisualsList";
import audioVisualsStoreModule from "./audioVisualsStoreModule";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BCardText,
    BCardBody,
    BTableLite,
    BTooltip,
    Logo,
    BSpinner,
    BBadge,

    vSelect,

    ValidationProvider,
    ValidationObserver,
    required,

    Ripple,
  },
  setup() {
    const AUDIO_CONTENT_STORE_MODULE = "content";
    // Use toast
    const toast = useToast();

    // Register module
    if (!store.hasModule(AUDIO_CONTENT_STORE_MODULE))
      store.registerModule(AUDIO_CONTENT_STORE_MODULE, audioVisualsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDIO_CONTENT_STORE_MODULE))
        store.unregisterModule(AUDIO_CONTENT_STORE_MODULE);
    });

    const isAddNewArticleSidebarActive = ref(false);

    const getContentRoles = (content) => {
      console.log(content);
    };

    const {
      fetchAudioVisuals,
      tableColumns,
      per_page,
      current_page,
      totalRecords,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAudioVisualsList,
      refetchData,
      resolveContentVariant,
    } = useAudioVisualsList();

    return {
      // Sidebar
      isAddNewArticleSidebarActive,

      fetchAudioVisuals,
      tableColumns,
      per_page,
      current_page,
      totalRecords,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAudioVisualsList,
      refetchData,

      resolveContentVariant,

      getContentRoles,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-header-btn {
  margin-right: 2px;
}
@media only screen and (max-width: 992px) {
  .table-header-btn {
    margin-top: 3px;
    margin-right: 0;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
